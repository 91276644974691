import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardPage from "views/Dashboard/Dashboard.js";
// import ProjectReport from "views/ProjectReport/ProjectReport";
// import Staffs from "views/Sfatts/Staffs";
// import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";
// import WorkLog from "views/WorkLog/WorkLog";
// import DescriptionIcon from "@material-ui/icons/Description";
import AddBoxIcon from "@material-ui/icons/AddBox";
// import DeveloperReport from "views/DeveloperReport/DeveloperReport";
import JournalPage from "views/JournalPage/JournalPage";
import ArticlesPage from "views/ArticlesPage/ArticlesPage";
import CallForArticlePage from "views/CallForArticlePage/CallForArticlePage";
import UserDetails from "views/UserDetails/UserDetails";
import Messages from "./views/Messages/Messages";
import ArticleSubmitted from "views/ArticlesSubmitted/ArticlesSubmitted";
import BulkMailPage from "views/BulkMailPage/BulkMailPage";
import FileCopyIcon from "@material-ui/icons/FileCopy";
// import DailyReportAdmin from "views/DailyReportAdmin/DailyReportAdmin";
import TodayIcon from "@material-ui/icons/Today";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/journal-page",
    name: "Add Journal",
    icon: AddBoxIcon,
    component: JournalPage,
    layout: "/admin",
  },
  {
    path: "/articles-page",
    name: "Add Articles",
    icon: AddBoxIcon,
    component: ArticlesPage,
    layout: "/admin",
  },
  {
    path: "/call-for-article-page",
    name: "Call For Article",
    icon: TodayIcon,
    component: CallForArticlePage,
    layout: "/admin",
  },
  {
    path: "/user-details",
    name: "User Details",
    icon: PeopleIcon,
    component: UserDetails,
    layout: "/admin",
  },
  {
    path: "/messages",
    name: "Messages",
    icon: FileCopyIcon,
    component: Messages,
    layout: "/admin",
  },
  {
    path: "/articles-submitted",
    name: "Articles Submitted",
    icon: BarChartIcon,
    component: ArticleSubmitted,
    layout: "/admin",
  },

  {
    path: "/bulk-mail",
    name: "Bulk Email",
    icon: "content_paste",
    component: BulkMailPage,
    layout: "/admin",
  },
  // {
  //   path: "/developers",
  //   name: "Developers",
  //   icon: PeopleIcon,
  //   component: Staffs,
  //   layout: "/admin",
  // },
  // {
  //   path: "/daily-report",
  //   name: "Daily Work Report",
  //   icon: TodayIcon,
  //   component: DailyReportAdmin,
  //   layout: "/admin",
  // },
  // {
  //   path: "/work-log",
  //   name: "Work Log",
  //   icon: DescriptionIcon,
  //   component: WorkLog,
  //   layout: "/admin",
  // },
  // {
  //   path: "/project-report",
  //   name: "Project Report",
  //   icon: BarChartIcon,
  //   component: ProjectReport,
  //   layout: "/admin",
  // },
  // {
  //   path: "/developer-report",
  //   name: "Developer Report",
  //   icon: FileCopyIcon,
  //   component: DeveloperReport,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
