import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
// import Table from "components/Table/Table.js";

// import ImageUpload from "components/CustomUpload/ImageUpload.js";

import AttachFile from "@material-ui/icons/AttachFile";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
// import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";

// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function CallForArticlePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [files, setFiles] = useState(null);
  const [validated, setValidated] = React.useState(true);
  // const [Developers, setDevelopers] = React.useState([]);
  const [userDetails, SetUserDetails] = React.useState([]);

  useEffect(() => {
    SetUserDetails(JSON.parse(window.localStorage.getItem("userdetails")));
  }, []);
  console.log("userDetails::", userDetails);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };

  //Form Data
  const [data, setData] = React.useState({
    Id: 0,
    MajorTheme: "",
    SubThemes: "",
    Description: "",
    Description2: "",
    Description3: "",
    SubmissionDate: "",
    Image: "",
  });

  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  function HandleClear() {
    setData({
      Id: 0,
      MajorTheme: "",
      SubThemes: "",
      Description: "",
      Description2: "",
      Description3: "",
      SubmissionDate: "",
      Image: "",
    });
  }
  //Function for Validating fields
  // function ValidateFields() {
  //   if (data.FullName == "") {
  //     return false;
  //   } else if (DeveloperValues.Id == null) {
  //     return false;
  //   } else if (data.Subject == "") {
  //     return false;
  //   } else if (data.Description == "") {
  //     return false;
  //   } else return true;
  // }
  //function to upload
  function UploadImage() {
    if (files != null) {
      let formData = new FormData();
      formData.append("Image", files[0]);
      // console.log(formData);
      axios({
        method: "post",
        url: Domain + "/upload_file.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("File Upload Response:", response.data);

          data.Image = response.data;
          HandleSave();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification();
        });
    } else {
      HandleSave();
    }
  }
  function HandleSave() {
    setValidated(true);
    let formData = new FormData();
    formData.append("Id", data.Id);
    formData.append("MajorTheme", data.MajorTheme);
    formData.append("SubThemes", data.SubThemes);
    formData.append("Description", data.Description);
    formData.append("Description2", data.Description2);
    formData.append("Description3", data.Description3);
    formData.append("SubmissionDate", data.SubmissionDate);
    formData.append("Image", data.Image);
    axios({
      method: "post",
      url: Domain + "/UpdateCallForArticle.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.success) {
          // HandleClear();
          console.log("saved");
          showSavedNotification();
        } else {
          console.log("failed");
          showDeletedNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        showDeletedNotification();
      });
  }
  // const [jounals, setJournals] = useState([]);
  // const [edit, setEdit] = useState("");
  // console.log(edit);
  useEffect(() => {
    let formData = new FormData();
    axios({
      method: "post",
      url: Domain + "/GetCallForArticle.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("reponse:GetCallForArticle :", response);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setData(response.data.data[0]);
          } else {
            console.log("failed");
          }
        } else {
          console.log("Journals empty");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  // let JournalData = jounals.map((d) => ({
  //   Id: d.Id,
  //   Date: d.Date.split(" ")[0],
  //   Title: d.Title,
  //   Articles: d.No_of_articles,
  //   Subject: d.Subject,
  //   Description: d.Description,
  //   Editor: d.Editor,
  // }));
  // useEffect(() => {
  //   if (edit != "") {
  //     let formData = new FormData();
  //     formData.append("Id", edit);
  //     axios({
  //       method: "post",
  //       url: Domain + "/GetJournalById.php",
  //       data: formData,
  //       config: { headers: { "Content-Type": "multipart/form-data" } },
  //     })
  //       .then(function (response) {
  //         //handle success
  //         console.log("Journals response:", response);
  //         if (response.data.TotalCount != 0) {
  //           if (response.data.success) {
  //             console.log("Success");
  //             console.log("Journals:", response.data.data);
  //             setData(response.data.data[0]);
  //           } else {
  //             console.log("failed");
  //           }
  //         } else {
  //           console.log("Journals empty");
  //         }
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //       });
  //   }
  // }, [edit]);
  // const DevelopersList = Developers.map((d) => ({
  //   value: d.Id,
  //   label: d.ProjectName,
  // }));
  // const [DeveloperValues, setDevelopersValue] = React.useState({
  //   Id: null,
  //   label: "",
  // });
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Saved Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error! Try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Call For Article </h4>
              <p className={classes.cardCategoryWhite}>
                Fill the details below
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <SingleSelect
                    noOptionsMessage="No projects available"
                    placeholder="Select Project"
                    Options={DevelopersList}
                    setValue={setDevelopersValue}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.MajorTheme}
                    id="MajorTheme"
                    labelText="Major Theme"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.SubmissionDate}
                    labelText="Submission Date"
                    id="SubmissionDate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type="Description"
                    onChange={(e) => HandleData(e)}
                    value={data.SubThemes}
                    id="SubThemes"
                    labelText="Sub Themes ( Use asterisk(*) to seperate each sub theme )"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.No_of_articles}
                    labelText="No_articles"
                    id="No_of_articles"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type="Description"
                    onChange={(e) => HandleData(e)}
                    value={data.Description}
                    labelText="Description"
                    id="Description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type="Description2"
                    onChange={(e) => HandleData(e)}
                    value={data.Description2}
                    labelText="Description 2"
                    id="Description2"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type="Description3"
                    onChange={(e) => HandleData(e)}
                    value={data.Description3}
                    labelText="Description 3"
                    id="Description3"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomFileInput
                    setFiles={setFiles}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "",
                    }}
                    endButton={{
                      buttonProps: {
                        round: true,
                        color: "info",
                        justIcon: true,
                      },
                      icon: <AttachFile />,
                    }}
                  />
                  {validated ? (
                    <></>
                  ) : (
                    <Danger>Input all the mandatory fields</Danger>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={HandleClear}>Clear</Button>
              <Button onClick={UploadImage} color="info">
                Save
              </Button>
            </CardFooter>
          </Card>
          {/* <Card>
            <CardBody></CardBody>
          </Card> */}
        </GridItem>
      </GridContainer>
    </>
  );
}
